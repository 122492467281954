import React, { FC } from 'react';

import classNames from 'classnames';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import { useScreenRecognition } from 'utils/hooks';
import { navigateToPage } from 'utils/functions';
import { toBoolean } from 'utils/stringUtils/stringUtils';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import NiceCard from '../../NiceCard';
import { ProductsCategoryListProps } from './models';
import { BottomCardContent, TopCardContent } from '../../NiceCard/models';
import ButtonPosition from '../../NiceCard/CardButtonPositionEnum';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProductsCategoryList.scss';

const ProductsCategoryList: FC<ProductsCategoryListProps> = ({
  productsCategoryList: {
    productsTitle,
    productsDescription,
    productContent,
    allProductsText,
    allProductsLink,
  },
  withSlider,
}) => {
  const handleNavigation = (link) => navigateToPage(link);
  const { isXl } = useScreenRecognition();

  const productContentList = productContent.map(
    ({ productButton, productImage, productImageAlt, boxTitle }) => {
      const { link, ariaLabel, text, withReverse } = Array.isArray(productButton)
        ? productButton[0]
        : productButton || {};
      const topCardContent = {
        cardImage: { imageAlt: productImageAlt, image: productImage },
        isColorOverlay: true,
      } as TopCardContent;
      const bottomCardContent = {
        title: boxTitle,
        buttons: [
          {
            text,
            ariaLabel,
            onClick: () => handleNavigation(link),
            position: ButtonPosition.Center,
            variant: { reversed: toBoolean(withReverse!) },
          },
        ],
      } as BottomCardContent;

      return (
        <div className="product-category__content" key={text}>
          <NiceCard {...{ topCardContent, bottomCardContent }} />
        </div>
      );
    }
  );

  return (
    <div className="products-category" data-testid="products-category-list">
      <h2 className="products-category__title">{productsTitle}</h2>
      {productsDescription ? (
        <div className="products-category__description">
          <DangerouslySetInnerHtml html={productsDescription} />
        </div>
      ) : null}
      <div
        className={classNames('product-category__wrapper', {
          'with--slider': withSlider,
        })}
      >
        {withSlider ? (
          <Slider
            infinite={false}
            variableWidth={!isXl}
            className="landing-page__reviews"
            slidesToShow={isXl ? productContent.length : 1}
          >
            {productContentList}
          </Slider>
        ) : (
          productContentList
        )}
      </div>
      {allProductsText && allProductsLink ? (
        <div className="products-category__all-products">
          <Link to={allProductsLink.url}>{allProductsText}</Link>
        </div>
      ) : null}
    </div>
  );
};

export default ProductsCategoryList;
