const createBreadcrumbs = (crumbs, areBreadcrumbsDisplayed, nodes, pathname) => {
  if (!areBreadcrumbsDisplayed) {
    return [];
  }

  if (pathname === '/') {
    return crumbs;
  }

  return crumbs.map((item) => {
    const contextData = nodes.find((node) => {
      const location = node?.context?.breadcrumb?.location;
      const contextLocation = location?.replace(/(.)\/?$/, '$1');

      return contextLocation === item.pathname;
    });

    if (contextData && contextData.context.breadcrumbName) {
      item.crumbLabel = contextData.context.breadcrumbName;
    } else {
      const preparedLabel = item.crumbLabel.replace(/-/g, ' ');
      item.crumbLabel = preparedLabel;
    }

    if (item.pathname !== '/' && item.pathname.slice(-1) !== '/') {
      const pathnameClosedBySlash = item.pathname.concat('/');
      item.pathname = pathnameClosedBySlash;
    }

    return item;
  });
};

export default createBreadcrumbs;
